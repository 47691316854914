import React from "react"
import { Link } from "gatsby"

import HeaderResources from "../../components/header_resources"

import BookPic1 from "../../static/images/book-summaries/book_01-960x550.jpg"
import BookPic2 from "../../static/images/book-summaries/book_02-960x550.jpg"
import BookPic3 from "../../static/images/book-summaries/book_03-960x550.jpg"
import BookPic4 from "../../static/images/book-summaries/book_04-960x550.jpg"
import BookPic5 from "../../static/images/book-summaries/book_05-960x550.jpg"

export default function BookSummaries() {
  const heroTitle = "Book Summaries"

  return (
    <HeaderResources heroTitle={heroTitle}>
      <h2 class="h2 h2-sm">{heroTitle}</h2>
      <div class="row">
        <div class="col-12 col-lg-6 col-md-6 mb-5">
          <Link to="/resources/book-summaries/one-up-on-wall-street" className={"post-wrapper"}>
            <div class="post-featured-image">
              <figure class="post-featured-image-hover">
                <img src={BookPic1} alt="One Up on Wall Street – Peter Lynch" class="img-fluid" />
              </figure>
            </div>
            <div class="post-content-wrapper">
              <h3 class="h5 h5-sm">One Up on Wall Street – Peter Lynch</h3>
              <div class="post-button-wrapper">
                <div class="continue-reading" title="One Up on Wall Street – Peter Lynch">
                  Continue Reading<span></span>
                </div>
              </div>
            </div>
          </Link>
        </div>
        <div class="col-12 col-lg-6 col-md-6 mb-5">
          <Link
            to="/resources/book-summaries/fooled-by-randomness-nassim-nicholas-taleb"
            className={"post-wrapper"}
          >
            <div class="post-featured-image">
              <figure class="post-featured-image-hover">
                <img
                  src={BookPic2}
                  alt="Fooled by Randomness – Nassim Nicholas Taleb (NNT)"
                  class="img-fluid"
                />
              </figure>
            </div>
            <div class="post-content-wrapper">
              <h3 class="h5 h5-sm">Fooled by Randomness – Nassim Nicholas Taleb (NNT)</h3>
              <div class="post-button-wrapper">
                <div
                  class="continue-reading"
                  title="Fooled by Randomness – Nassim Nicholas Taleb (NNT)"
                >
                  Continue Reading<span></span>
                </div>
              </div>
            </div>
          </Link>
        </div>
        <div class="col-12 col-lg-6 col-md-6 mb-5">
          <Link
            to="/resources/book-summaries/the-most-important-thing-howard-marks"
            className={"post-wrapper"}
          >
            <div class="post-featured-image">
              <figure class="post-featured-image-hover">
                <img
                  src={BookPic3}
                  alt="The Most Important Thing – Howard Marks (HM)"
                  class="img-fluid"
                />
              </figure>
            </div>
            <div class="post-content-wrapper">
              <h3 class="h5 h5-sm">The Most Important Thing – Howard Marks (HM)</h3>
              <div class="post-button-wrapper">
                <div class="continue-reading" title="The Most Important Thing – Howard Marks (HM)">
                  Continue Reading<span></span>
                </div>
              </div>
            </div>
          </Link>
        </div>

        <div class="col-12 col-lg-6 col-md-6 mb-5">
          <Link
            to="/resources/book-summaries/influence-the-psychology-of-persuasion-by-robert-b-cialdini"
            className={"post-wrapper"}
          >
            <div class="post-featured-image">
              <figure class="post-featured-image-hover">
                <img
                  src={BookPic4}
                  alt="Influence – The Psychology of Persuasion by Robert B. Cialdini (RBC)"
                  class="img-fluid"
                />
              </figure>
            </div>
            <div class="post-content-wrapper">
              <h3 class="h5 h5-sm">
                Influence – The Psychology of Persuasion by Robert B. Cialdini (RBC)
              </h3>
              <div class="post-button-wrapper">
                <div
                  class="continue-reading"
                  title="Influence – The Psychology of Persuasion by Robert B. Cialdini (RBC)"
                >
                  Continue Reading<span></span>
                </div>
              </div>
            </div>
          </Link>
        </div>
        <div class="col-12 col-lg-6 col-md-6 mb-5">
          <Link
            to="/resources/book-summaries/the-little-book-that-builds-wealth-pat-dorsey"
            className={"post-wrapper"}
          >
            <div class="post-featured-image">
              <figure class="post-featured-image-hover">
                <img
                  src={BookPic5}
                  alt="The Little Book That Builds Wealth – Pat Dorsey (PD)"
                  class="img-fluid"
                />
              </figure>
            </div>
            <div class="post-content-wrapper">
              <h3 class="h5 h5-sm">The Little Book That Builds Wealth – Pat Dorsey (PD)</h3>
              <div class="post-button-wrapper">
                <div
                  class="continue-reading"
                  title="The Little Book That Builds Wealth – Pat Dorsey (PD)"
                >
                  Continue Reading<span></span>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </HeaderResources>
  )
}
