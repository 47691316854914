import React from "react"

import Seo from "./seo"
import HeroInner from "./heroinner"

export default function HeaderWrapper({
  heroTitle = null,
  heroImage = null,
  heroMobileImage = null,
  breadcrumbSecondaryItem = null,
  breadcrumbSecondaryItemUrl = null,
  NavComponent,
  layout = "2Column",
  children,
}) {
  return (
    <>
      <Seo title={heroTitle} />
      <HeroInner
        heroTitle={heroTitle}
        heroImage={heroImage}
        heroMobileImage={heroMobileImage}
        breadcrumbSecondaryItem={breadcrumbSecondaryItem}
        breadcrumbSecondaryItemUrl={breadcrumbSecondaryItemUrl}
      />
      {layout === "2Column" && (
        <section class="c-section c-section-sm">
          <div class="container">
            <div class="row">
              <aside class="col-12 col-lg-3 col-md-4 order1">
                <NavComponent />
              </aside>

              <div class="col-12 col-lg-9 col-md-8 order2">
                <div class="innerpage--content innerpage--content-sm">{children}</div>
              </div>
            </div>
          </div>
        </section>
      )}
      {layout === "1Column" && (
        <section class="c-section c-section-sm">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-12 col-lg-10 col-md-10 col-sm-12">{children}</div>
            </div>
          </div>
        </section>
      )}
    </>
  )
}
