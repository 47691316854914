import React from "react"

import HeaderWrapper from "./header_wrapper"
import NavResources from "./nav_resources"

export default function HeaderResources({ heroTitle = null, layout = "2Column", children }) {
  const heroImage = "/images/slider/Resources--Banner.jpg"
  const heroMobileImage = "/images/slider/mobile/Resources--Banner.jpg"
  const breadcrumbSecondaryItem = "Resources"
  const breadcrumbSecondaryItemUrl = "/resources/investment-letters"

  return (
    <HeaderWrapper
      heroTitle={heroTitle}
      heroImage={heroImage}
      heroMobileImage={heroMobileImage}
      breadcrumbSecondaryItem={breadcrumbSecondaryItem}
      breadcrumbSecondaryItemUrl={breadcrumbSecondaryItemUrl}
      NavComponent={layout === "1Column" ? null : NavResources}
      layout={layout}
    >
      {children}
    </HeaderWrapper>
  )
}
