import React from "react"
import { Link } from "gatsby"

export default function NavResources() {
  return (
    <>
      <Link to="/" className="back--home">
        <i class="fas fa-chevron-left"></i> <span>Back to homepage</span>
      </Link>

      <ul class="sub--pages sub--pages-sm">
        {/* <li>
          <Link to="/resources/investment-letters" activeClassName="is--active">
            Investment Letters
          </Link>
        </li> */}
        <li>
          <Link to="/resources/blog" activeClassName="is--active">
            Blog
          </Link>
        </li>
        <li>
          <Link to="/resources/media" activeClassName="is--active">
            Media
          </Link>
        </li>
        <li>
          <Link to="/resources/tools" activeClassName="is--active">
            Tools
          </Link>
        </li>
        <li>
          <Link to="/resources/book-summaries" activeClassName="is--active">
            Book Summaries
          </Link>
        </li>
      </ul>
    </>
  )
}
