import React from "react"
import { Link } from "gatsby"
import Anime from "react-anime"

export default function HeroInner({
  heroImage = null,
  heroMobileImage = null,
  heroTitle = null,
  breadcrumbSecondaryItem = null,
  breadcrumbSecondaryItemUrl = null,
  ...rest
}) {
  let videoComment =
    '<!--[if IE 9]><video style="display:none;"><![endif]--><source media="(max-width: 767px)" sizes="33vw" srcset="' +
    heroMobileImage +
    ' 2x" /><source media="(min-width: 768px)" sizes="100vw" srcset="' +
    heroImage +
    ' 2x" /><!--[if IE 9]></video><![endif]--><img class="image-cover apricus-banner-image" sizes="(min-width: 601px) 33vw, 100vw" srcset="' +
    heroMobileImage +
    " 767w, " +
    heroImage +
    ' 1200w" alt="' +
    heroTitle +
    '" />'

  return (
    <>
      <div class="o-content-slider">
        <div id="heroslider" class="o-media-hero_inner relative">
          <div class="o-media-slider">
            <div class="o-media-flexslider js-flexslider">
              <ul class="o-media-slides slides">
                <li class="o-media-slide slide slide-1">
                  <span class="hero--overlay"></span>
                  <Anime
                    easing="easeOutExpo"
                    duration={1200}
                    direction="alternate"
                    loop={false}
                    delay={(el, index) => index * 240}
                    translateX="0rem"
                    scale={[1.6, 1]}
                    opacity={[0, 1]}
                  >
                    <picture dangerouslySetInnerHTML={{ __html: videoComment }}></picture>
                  </Anime>
                  <div class="o-media-hero_overlay">
                    <div class="o-media-hero_caption">
                      <div class="o-media-hero_caption_balance">
                        <div class="container">
                          <div class="o-cards-view__container o-cards-view__container-xs">
                            <div class="o-cards-view__info apricus-main-title">
                              <Anime
                                easing="easeOutExpo"
                                duration={1500}
                                direction="alternate"
                                loop={false}
                                delay={(el, index) => index * 30}
                                translateX={[-90, 0]}
                                opacity={[0, 1]}
                              >
                                <h1 class="o-cards-view__info--heading o-cards-view__info--heading-xs">
                                  {heroTitle}
                                </h1>
                              </Anime>
                              <ol class="breadcrumb">
                                <li>
                                  <Link to="/">Home</Link>
                                </li>
                                {breadcrumbSecondaryItem && breadcrumbSecondaryItemUrl && (
                                  <li class="">
                                    <Link to={breadcrumbSecondaryItemUrl}>
                                      {breadcrumbSecondaryItem}
                                    </Link>
                                  </li>
                                )}
                                <li class="active">{heroTitle}</li>
                              </ol>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="clearfix"></div>
    </>
  )
}
